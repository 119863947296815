import styled from '@emotion/styled-base'
import withStyles from '@material-ui/core/styles/withStyles'
import React from 'react'

const LoadingWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-items: center;
  width: 100%;
  height: 100vh;

  * {
    flex: 1;
  }
`

const Loading = () => <LoadingWrapper />

export default withStyles(null, { withTheme: true })(Loading)
