import React from 'react'
import { Subscribe } from 'unstated'

import SettingsContainer from '../containers/SettingsContainer'
import { toCurrency } from '../util'

const Currency = ({ amount = 0 }) => (
  <Subscribe to={[SettingsContainer]}>
    {settings =>
      toCurrency(
        isFinite(amount) ? amount : 0,
        settings.state.currency,
        settings.state.locale
      )
    }
  </Subscribe>
)

export default Currency
