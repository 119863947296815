import styled from '@emotion/styled-base'
import { sortBy, sumBy } from 'lodash'
import React from 'react'
import { Container, Subscribe } from 'unstated'

import withStyles from '@material-ui/core/styles/withStyles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'

import AppBarDatePicker from '../components/app-bar-datepicker'
import Currency from '../components/currency'
import Layout from '../components/layout'
import Loading from '../components/loading'
import { SAVINGS_COMPONENTS } from '../constants'
import FiltersContainer, { FiltersState } from '../containers/FiltersContainer'
import SavingsContainer, { SavingsState } from '../containers/SavingsContainer'
import { isBootstrapped } from '../containers/withLocalStorage'
import withRoot from '../withRoot'

type ComponentGrouping = Record<string, AccountGrouping>
type AccountGrouping = Record<string, AccountSummary>

interface AccountSummary {
  amount: number
  tags: string[]
}

const ComponentTableRow = styled(TableRow)`
  background: ${props => props.theme.palette.grey['200']};
  td {
    padding-top: ${props => props.theme.spacing.unit * 2}px;
    padding-bottom: ${props => props.theme.spacing.unit * 2}px;
  }
`

const ThemedComponentTableRow = withStyles(theme => ({}), { withTheme: true })(
  ComponentTableRow
)

const ContributionsPage = ({ location: { pathname } }) => (
  <Subscribe to={[SavingsContainer, FiltersContainer]}>
    {(
      savingsData: Container<SavingsState>,
      filters: Container<FiltersState>
    ) => {
      if (!isBootstrapped(savingsData)) {
        return <Loading />
      }

      const tableData: ComponentGrouping = {
        S1: {},
        S2: {},
        S3: {},
        C: {},
        D: {},
        T: {}
      }

      const periods = sortBy(savingsData.state.periods, p => p.year).filter(
        period =>
          filters.state.year ? period.year === filters.state.year : true
      )

      periods.forEach(period => {
        if (period.data) {
          period.data.forEach(periodData => {
            if (tableData[periodData.type][periodData.name]) {
              tableData[periodData.type][periodData.name].amount +=
                periodData.value
            } else {
              tableData[periodData.type][periodData.name] = {
                amount: periodData.value,
                tags: periodData.tags || [],
              }
            }
          })
        }
      })

      return (
        <Layout
          appBarContent={<AppBarDatePicker />}
          title={`Totals for ${
            filters.state.year ? filters.state.year : 'All time'
          }`}
          pathname={pathname}
        >
          <Typography
            gutterBottom
            color="textSecondary"
          >
            Totals displayed are for the <em>calendar</em> year, not tax
            year.
          </Typography>

          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Account</TableCell>
                  <TableCell align="right">Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(tableData).map(([type, accountGroup]) => (
                  <>
                    <ThemedComponentTableRow>
                      <TableCell>
                        <Typography>
                          {SAVINGS_COMPONENTS[type].heading}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                          {SAVINGS_COMPONENTS[type].description}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="subtitle2">
                          <Currency amount={sumBy(Object.values(accountGroup), group => group.amount)} />
                        </Typography>
                      </TableCell>
                    </ThemedComponentTableRow>
                    {Object.entries(accountGroup).map(([name, summary]) => (
                      <TableRow data-test-account={name}>
                        <TableCell>
                          <Typography data-testid="account-name">
                            {name}
                          </Typography>
                          {/*<Typography variant="caption" color="textSecondary">
                            {summary.tags.join(', ')}
                          </Typography>*/}
                        </TableCell>
                        <TableCell data-testid="account-amount" align="right">
                          <Currency amount={summary.amount} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Layout>
      )
    }}
  </Subscribe>
)

export default withRoot(ContributionsPage)
